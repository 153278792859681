<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M10.998 4.41722C11.0099 4.40527 11.022 4.39352 11.0342 4.382C11.1601 4.26381 11.3024 4.17305 11.4534 4.10971C11.6215 4.03905 11.8062 4 12 4L12.0042 4.00001C12.3641 4.00108 12.7237 4.13891 12.9983 4.4135L19.5865 11.0017C20.1378 11.5531 20.1378 12.4469 19.5865 12.9983C19.0352 13.5496 18.1413 13.5496 17.59 12.9983L13.4118 8.82005V18.5882C13.4118 19.3679 12.7797 20 12 20C11.2203 20 10.5882 19.3679 10.5882 18.5882V8.82008L6.41003 12.9983C5.8587 13.5496 4.96482 13.5496 4.4135 12.9983C3.86217 12.4469 3.86217 11.5531 4.4135 11.0017L10.998 4.41722Z"
    />
  </svg>
</template>
