<template>
  <component
    :is="componentType"
    :type="link ? 'href' : 'button'"
    :to="url"
    class="fill-grey-900 font-bold uppercase italic tracking-wider text-grey-900 no-underline hover:underline hover:decoration-2 hover:underline-offset-4 focus:rounded focus:outline focus:outline-offset-2 focus:outline-green-800"
    :class="[
      small ? 'text-sm' : 'text-base',
      { 'flex items-center justify-center': link },
    ]"
    :external="external"
  >
    <span class="flex items-center gap-2">
      <slot />
      <IconArrowDown
        v-if="arrow === 'down'"
        class="h-4 w-4 shrink-0 fill-inherit"
      />
      <IconArrowLeft
        v-if="arrow === 'left'"
        class="h-4 w-4 shrink-0 fill-inherit"
      />
      <IconArrowRight
        v-if="arrow === 'right'"
        class="h-4 w-4 shrink-0 fill-inherit"
      />
      <IconArrowUp
        v-if="arrow === 'up'"
        class="h-4 w-4 shrink-0 fill-inherit"
      />
      <IconArrowUpRight
        v-if="arrow === 'upRight'"
        class="h-4 w-4 fill-inherit"
      />
    </span>
  </component>
</template>

<script setup lang="ts">
const props = defineProps<{
  arrow?: "down" | "left" | "right" | "up" | "upRight" | "none"; //** none for off
  link?: boolean;
  url?: string;
  small?: boolean;
  external?: boolean;
}>();

const componentType = computed(() =>
  props.link ? resolveComponent("NuxtLink") : "button",
);
</script>
