<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24x"
  >
    <path
      d="M13.4118 5.41176C13.4118 4.63207 12.7797 4 12 4C11.2203 4 10.5882 4.63207 10.5882 5.41176V15.1799L6.41003 11.0017C5.8587 10.4504 4.96482 10.4504 4.4135 11.0017C3.86217 11.5531 3.86217 12.4469 4.4135 12.9983L10.9977 19.5824C11.0103 19.5952 11.0232 19.6077 11.0363 19.62C11.1601 19.7357 11.2996 19.825 11.4477 19.8879C11.6173 19.9601 11.804 20 12 20C12.1904 20 12.3719 19.9623 12.5377 19.894C12.7052 19.8251 12.8622 19.7226 12.9983 19.5865L19.5865 12.9983C20.1378 12.4469 20.1378 11.5531 19.5865 11.0017C19.0352 10.4504 18.1413 10.4504 17.59 11.0017L13.4118 15.1799V5.41176Z"
    />
  </svg>
</template>
